import "./OficinasTerminales.css";
import { useState, useEffect } from "react";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import EstadoCruce from '../../components/EstadoCruce/EstadoCruce';
import Navegacion from "../../components/Navegacion/Navegacion";
import VolverInicio from "../../components/VolverInicio/VolverInicio";
import Footer from "../../components/Footer/Footer";
import Chat from "../../components/Chat/Chat";
import ImgBack from "./src/img_back.jpg";
import moment from "moment";
import Icon from "./src/icon.svg";

import { useTranslation } from "react-i18next";

function OficinasTerminales() {
    const [t, i18n] = useTranslation("global");

    const [cruces, setCruces] = useState([]);
    const [crucesSuspendidos, setCrucesSuspendidos] = useState([]);
    const [showCruce, setShowCruce] = useState(false);
    const [rutas, setRutas] = useState([]);

    const [oficinas, setOficinas] = useState([
      {
        "titulo": "",
        "oficinas": [
        ]
      },
      {
        "titulo": "",
        "oficinas": [
        ]
      }
    ]);


  useEffect(() => {
    
    let firstDate = moment(new Date()).format("DD-MM-yyyy");
    let secondDate = moment(new Date().setDate(new Date().getDate() + 1)).format("DD-MM-yyyy");

    fetch(`https://api-cruce.tabsa.cl/api/cruce/20/fecha/${firstDate}/embarcadero/0`, { method: "GET" }).then(firstDateResponse => {
        if (firstDateResponse.ok) {
            firstDateResponse.json().then(firstDateData => { 

                let newCruces = firstDateData.data.map((d) => ({ 
                    estado: d.estado_detalle.toLowerCase(), 
                    origen: d.origen, 
                    destino: d.destino, 
                    hora: d.zarpeOriginal.split(" ")[1].substring(0, 5), 
                    fecha: d.zarpeOriginal.split(" ")[0]
                }))
                setCruces(newCruces);

                fetch(`https://api-cruce.tabsa.cl/api/cruce/20/fecha/${secondDate}/embarcadero/0`, { method: "GET" }).then(secondDateResponse => {
                    if (secondDateResponse.ok) {
                        secondDateResponse.json().then(secondDateData => { 
                            
                            let newSecondCruces = secondDateData.data.map((d) => ({ 
                                estado: d.estado_detalle.toLowerCase(), 
                                origen: d.origen, 
                                destino: d.destino, 
                                hora: d.zarpeOriginal.split(" ")[1].substring(0, 5), 
                                fecha: d.zarpeOriginal.split(" ")[0]
                            }))

                            let concats = newCruces.concat(newSecondCruces);
                            setCruces(concats);

                            let newCrucesSuspendidos = [];
                
                            concats.map((d) => {
                                if (d.estado === "suspendido" || d.estado === "cancelado") {
                                    newCrucesSuspendidos.push(d);
                                }
                            });

                            setCrucesSuspendidos(newCrucesSuspendidos);
                            setShowCruce(newCrucesSuspendidos.length > 0)
                        })
                    }
                    else {
                        console.log(`ERROR ${secondDateResponse.url} status ${secondDateResponse.status} ${secondDateResponse.statusText}`)
                    }

                })
            })
        }
        else {
            console.log(`ERROR ${firstDateResponse.url} status ${firstDateResponse.status} ${firstDateResponse.statusText}`)
        }
    }).catch(error => {
      console.log(JSON.stringify(error));
    });

    fetch(`${process.env.REACT_APP_API_BASEURL}/Rutas/rutas`, { method: "GET" }).then(response => {
        if (response.ok) {
          response.json().then(data => {
            setRutas(data.data);
          })
        }
        else {
          console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
        }
    }).catch(error => {
        console.log(JSON.stringify(error));
    });
  }, [])

  const [chatOpen, setChatOpen] = useState(false);
  const [showSelectLanguage, setShowSelectLanguage] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);

  function showItems(chat, calendarioIda, calendarioVuelta, idiomas, login) {
    setChatOpen(chat);
    setShowSelectLanguage(idiomas);
    setLoginOpen(login);
  }

    return (
        <div className="OficinasTerminales">
          <EstadoCruce clickOut={showItems} cruces={cruces} crucesSuspendidos={crucesSuspendidos} showCruce={showCruce} />
          <div className={`slider-top-shadow${!showCruce ? "-off": ""}`}>
            <Navegacion clickOut={showItems} rutas={rutas} showSelectLanguage={showSelectLanguage} />
          </div>
          <div className="div-oficinas-terminales-s" onClick={() => { showItems(false, false, false, false, false) }}>
            <img className="img-oficinas-terminales-s" alt={"back image"} src={ImgBack} />
          </div>
          <div className="div-oficinas-terminales-body" onClick={() => { showItems(false, false, false, false, false) }}>
            <Container>
              <Row className="justify-content-center">
                <Col lg={8}>
                    <p className="title-oficinas-terminales-s">{t("nosotros.oficinas-terminales.titulo")}</p>
                </Col>
              </Row>
              <br />
              <br />
              <Row className="justify-content-center">
                <Col lg={11}>
                  <Row>
                    <Col lg={6} md={6}>
                      <p className="subtitle-oficinas-terminales-s"> <img src={Icon} alt="icon" /> {t("nosotros.oficinas-terminales.titulo")}</p>
                      <table className="table-oficinas-terminales">
                      <thead>
                        <tr>
                          <td style={{ width: "50%" }}>{t("nosotros.oficinas-terminales.area")}</td>
                          <td>Oficina Central</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{t("nosotros.oficinas-terminales.direccion")}</td>
                          <td>Juan Williams 06450, Punta Arenas</td>
                        </tr>
                        <tr>
                          <td>{t("nosotros.oficinas-terminales.localidad")}</td>
                          <td>Punta Arenas</td>
                        </tr>
                        <tr>
                          <td>{t("nosotros.oficinas-terminales.region")}</td>
                          <td>Magallanes</td>
                        </tr>
                        <tr>
                          <td>{t("nosotros.oficinas-terminales.telefono")}</td>
                          <td>(61) 272 8100</td>
                        </tr>
                        <tr>
                          <td>{t("nosotros.oficinas-terminales.adicionales")}</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>{t("nosotros.oficinas-terminales.ruta-servicio")}</td>
                          <td>Atención a clientes.</td>
                        </tr>
                      </tbody>
                    </table>
                    </Col>
                    <Col lg={6} md={6}>
                      <li className="li-title-oficinas-terminales">Cruces y Servicios en el Estrecho de Magallanes</li>
                      <table className="table-oficinas-terminales">
                        <thead>
                          <tr>
                            <td style={{ width: "50%" }}>{t("nosotros.oficinas-terminales.area")}</td>
                            <td>Operaciones Punta Delgada</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.direccion")}</td>
                            <td>Terminal Punta Delgada</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.localidad")}</td>
                            <td>San Gregorio</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.region")}</td>
                            <td>Magallanes</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.telefono")}</td>
                            <td>(61) 272 8100</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.adicionales")}</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.ruta-servicio")}</td>
                            <td>Balseo Estrecho de Magallanes, Primera Angostura: Punta Delgada y Bahía Azul.</td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={6}>
                      <table className="table-oficinas-terminales">
                        <thead>
                          <tr>
                            <td style={{ width: "50%" }}>{t("nosotros.oficinas-terminales.area")}</td>
                            <td>Operaciones Tres Puentes</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.direccion")}</td>
                            <td>Embarcadero Tres puentes, Punta Arenas</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.localidad")}</td>
                            <td>Punta Arenas</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.region")}</td>
                            <td>Magallanes</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.telefono")}</td>
                            <td>(61) 272 8100</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.adicionales")}</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.ruta-servicio")}</td>
                            <td>Ruta Punta Arenas - Porvenir, Punta Arenas - Puerto Williams, Venta y Reserva de Pasajes.</td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                    <Col lg={6} md={6}>
                      <table className="table-oficinas-terminales">
                        <thead>
                          <tr>
                            <td style={{ width: "50%" }}>{t("nosotros.oficinas-terminales.area")}</td>
                            <td>Oficina Porvenir</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.direccion")}</td>
                            <td>Señoret 831</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.localidad")}</td>
                            <td>Porvenir</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.region")}</td>
                            <td>Magallanes</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.telefono")}</td>
                            <td>(61) 272 8100</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.adicionales")}</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.ruta-servicio")}</td>
                            <td>Ruta Punta Arenas - Porvenir</td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={6}>
                      <li className="li-title-oficinas-terminales">Ruta Puerto Yungay, Caleta Tortel, Puerto Edén y Puerto Natales</li>
                    </Col>
                    <Col lg={6} md={6}>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={6}>
                      <table className="table-oficinas-terminales">
                        <thead>
                          <tr>
                            <td style={{ width: "50%" }}>{t("nosotros.oficinas-terminales.area")}</td>
                            <td>Operaciones Puerto Natales</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.direccion")}</td>
                            <td>Av. Costanera Pedro Montt N°605</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.localidad")}</td>
                            <td>Puerto Natales</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.region")}</td>
                            <td>Magallanes</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.telefono")}</td>
                            <td>(61) 272 8100</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.adicionales")}</td>
                            <td>(61) 241 5966</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.ruta-servicio")}</td>
                            <td>Ruta Puerto Yungay, Caleta Tortel, Puerto Edén, Puerto Natales.</td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                    <Col lg={6} md={6}>
                      <table className="table-oficinas-terminales">
                        <thead>
                          <tr>
                            <td style={{ width: "50%" }}>{t("nosotros.oficinas-terminales.area")}</td>
                            <td>Oficina Coyhaique</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.direccion")}</td>
                            <td>Prat esquina Freire</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.localidad")}</td>
                            <td>Coyhaique</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.region")}</td>
                            <td>Aysén</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.telefono")}</td>
                            <td>+56 9 32648625</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.adicionales")}</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.ruta-servicio")}</td>
                            <td>Ruta Puerto Yungay, Caleta Tortel, Puerto Edén, Puerto Natales.</td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={6}>
                      <table className="table-oficinas-terminales">
                        <thead>
                          <tr>
                            <td style={{ width: "50%" }}>{t("nosotros.oficinas-terminales.area")}</td>
                            <td>Operaciones Caleta Tortel</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.direccion")}</td>
                            <td>Sector Centro S/N, Caleta Tortel</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.localidad")}</td>
                            <td>Caleta Tortel</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.region")}</td>
                            <td>Aysén</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.telefono")}</td>
                            <td>(61) 272 8100</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.adicionales")}</td>
                            <td>+56 9 8452 7228</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.ruta-servicio")}</td>
                            <td>Ruta Puerto Yungay, Caleta Tortel, Puerto Edén, Puerto Natales.</td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                    <Col lg={6} md={6}>
                      <table className="table-oficinas-terminales">
                        <thead>
                          <tr>
                            <td style={{ width: "50%" }}>{t("nosotros.oficinas-terminales.area")}</td>
                            <td>Operaciones Puerto Yungay</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.direccion")}</td>
                            <td>Sector Centro S/N, Caleta Tortel</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.localidad")}</td>
                            <td>Caleta Tortel</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.region")}</td>
                            <td>Aysén</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.telefono")}</td>
                            <td>(61) 272 8100</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.adicionales")}</td>
                            <td>+56 9 8452 7228</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.ruta-servicio")}</td>
                            <td>Ruta Puerto Yungay, Caleta Tortel, Puerto Edén, Puerto Natales.</td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={6}>
                      <table className="table-oficinas-terminales">
                        <thead>
                          <tr>
                            <td style={{ width: "50%" }}>{t("nosotros.oficinas-terminales.area")}</td>
                            <td>Operaciones Puerto Edén</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.direccion")}</td>
                            <td>Rampa Puerto Edén</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.localidad")}</td>
                            <td>Puerto Natales</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.region")}</td>
                            <td>Aysén</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.telefono")}</td>
                            <td>(61) 272 8100</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.adicionales")}</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.ruta-servicio")}</td>
                            <td>Ruta Puerto Yungay, Caleta Tortel, Puerto Edén, Puerto Natales.</td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                    <Col lg={6} md={6}>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={6}>
                      <li className="li-title-oficinas-terminales">Ruta Punta Arenas, Puerto Williams y Puerto Toro</li>
                    </Col>
                    <Col lg={6} md={6}>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={6}>
                      <table className="table-oficinas-terminales">
                        <thead>
                          <tr>
                            <td style={{ width: "50%" }}>{t("nosotros.oficinas-terminales.area")}</td>
                            <td>Operaciones Puerto Williams</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.direccion")}</td>
                            <td>Rampa Puerto Williams</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.localidad")}</td>
                            <td>Cabo de Hornos</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.region")}</td>
                            <td>Magallanes</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.telefono")}</td>
                            <td>(61) 272 8100</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.adicionales")}</td>
                            <td>+56 9 7765 9244</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.ruta-servicio")}</td>
                            <td>Ruta Punta Arenas, Puerto Williams, Puerto Toro, Caleta 2 de Mayo.</td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                    <Col lg={6} md={6}>
                      <table className="table-oficinas-terminales">
                        <thead>
                          <tr>
                            <td style={{ width: "50%" }}>{t("nosotros.oficinas-terminales.area")}</td>
                            <td>Operaciones Puerto Toro</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.direccion")}</td>
                            <td>Rampa Puerto Toro</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.localidad")}</td>
                            <td>Cabo de Hornos</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.region")}</td>
                            <td>Magallanes</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.telefono")}</td>
                            <td>(61) 272 8100</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.adicionales")}</td>
                            <td>+56 9 7765 9244</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.ruta-servicio")}</td>
                            <td>Ruta Punta Arenas, Puerto Williams, Puerto Toro, Caleta 2 de Mayo.</td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={6}>
                      <li className="li-title-oficinas-terminales">Cruce Río Verde Isla Riesco</li>
                    </Col>
                    <Col lg={6} md={6}>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={6}>
                      <table className="table-oficinas-terminales">
                        <thead>
                          <tr>
                            <td style={{ width: "50%" }}>{t("nosotros.oficinas-terminales.area")}</td>
                            <td>Operaciones Canal Fitz Roy</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.direccion")}</td>
                            <td>Rampa Río Verde</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.localidad")}</td>
                            <td>Río Verde</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.region")}</td>
                            <td>Magallanes</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.telefono")}</td>
                            <td>(61) 272 8100</td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.adicionales")}</td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>{t("nosotros.oficinas-terminales.ruta-servicio")}</td>
                            <td>Cruce Canal Fitz Roy: Río Verde, Isla Riesco.</td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                    <Col lg={6} md={6}>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
          <Chat clickOut={showItems} chatOpen={chatOpen}/>
          <Footer clickOut={showItems} />
          <VolverInicio Path={"/inicio"} Texto={"volver-inicio.volver"} />
        </div>
    );
}

export default OficinasTerminales;
