import "./Beneficios.css";
import { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import EstadoCruce from '../../components/EstadoCruce/EstadoCruce';
import Navegacion from "../../components/Navegacion/Navegacion";
import VolverInicio from "../../components/VolverInicio/VolverInicio";
import Footer from "../../components/Footer/Footer";
import Chat from "../../components/Chat/Chat";
import ImgQuienesSomos from "./src/quienes_somos_1.png";
import ValoresA from "./src/valores_1.svg";
import ValoresB from "./src/valores_2.svg";
import ValoresC from "./src/valores_3.svg";
import ValoresD from "./src/valores_4.svg";
import ValoresE from "./src/valores_5.svg";
import ImgFoto1 from "./src/foto_1.png";
import ImgFoto2 from "./src/foto_2.png";
import ImgFoto3 from "./src/foto_3.png";
import ImgFoto4 from "./src/foto_4.png";
import moment from "moment";

import { useTranslation } from "react-i18next";

function QuienesSomos() {
    const [t, i18n] = useTranslation("global");
    const [showCruce, setShowCruce] = useState(false);
    const [rutas, setRutas] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASEURL}/Rutas/rutas`, { method: "GET" }).then(response => {
      if (response.ok) {
        response.json().then(data => {
          setRutas(data.data);
        })
      }
      else {
        console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
      }
    }).catch(error => {
        console.log(JSON.stringify(error));
    });
  }, [])

  const [chatOpen, setChatOpen] = useState(false);
  const [showSelectLanguage, setShowSelectLanguage] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);

  function showItems(chat, calendarioIda, calendarioVuelta, idiomas, login) {
    setChatOpen(chat);
    setShowSelectLanguage(idiomas);
    setLoginOpen(login);
  }

    return (
        <div className="QuienesSomos">

          <div className={`slider-top-shadow${!showCruce ? "-off": ""}`}>
            <Navegacion clickOut={showItems} rutas={rutas} showSelectLanguage={showSelectLanguage} />
          </div>

          <div className="div-quienes-somos-s" clickOut={() => { showItems(false, false, false, false, false) }}>
            <img className="img-quienes-somos-t-s" alt={"quienes somos"} src={ImgQuienesSomos} />
          </div>
          <div className="div-quienes-somos-body" clickOut={() => { showItems(false, false, false, false, false) }}>
            <Container>
              <Row className="justify-content-center row-content-qss-s">
                <Col lg={10} md={11} sm={11} xs={11}>
                    <p className="title-quienes-somos-s">Beneficios</p>
                    <p className="p-quienes-somos-s">

                    ¡Te invitamos a registrarte en nuestra plataforma de usuarios y disfrutar de una experiencia mejorada y personalizada! Al unirte, podrás acceder a una serie de beneficios exclusivos que harán tu proceso de compra más ágil y conveniente. ¡No esperes más y regístrate hoy mismo para aprovechar todas estas ventajas!                    </p>
                    <p className="p-quienes-somos-s">
                      <ul>
                          <li>
                            Agilidad en el proceso de compra (Debido a que al registrarse ingresa sus datos, el usuario puede ingresar a reservas o preventa ya con sus datos rellenados)
                          </li>
                          <li>
                            Al registrarse en la plataforma puede seleccionar notificaciones a través de WhatsApp de que servicio desea recibir. (por ejemplo, si quiere recibir información de primera Angostura o del servicio de Williams si no ha comprado, siempre le llegara un wsp si ha comprado un pasaje, nominativo)
                          </li>
                          <li>
                            Puede modificar sus datos / solicitar la edición con un ejecutivo
                          </li>
                          <li>
                            Descarga y visualización de boletas electrónicas
                          </li>
                          <li>
                            Solicitud de beneficios para aplicar a descuentos (minusválidos, tercera edad, embarazadas)
                          </li>
                          <li>
                            Al ingresar a preventa registrado como residente aplica la exención de IVA en boletas
                          </li>
                          <li>
                            Gestión de Reservas (Pronto)
                          </li>
                        </ul>
                    </p>
                      
                    <button className="btn-registro">
                      <a className="a-registro" href="https://plataforma-usuarios.tabsa.cl/crear-cuenta" target="blank_">Registrate Aquí</a>
                    </button>
                    <button className="btn-inicia">
                      <a className="a-inicia" href="https://plataforma-usuarios.tabsa.cl" target="blank_">Inicia Sesión</a>
                    </button>

                </Col>
              </Row>
              {/* <Row className="justify-content-center row-content-qss-s">
                <Col lg={8} md={11} sm={11} xs={11}>
                    <Row className="justify-content-center">
                        <Col className="no-margin" lg={4} md={4} sm={4} xs={4}>
                            <img className="img-quienes-somos-s" alt="Quienes somos" src={ImgFoto1} />
                            <img className="img-quienes-somos-s" alt="Quienes somos" src={ImgFoto2} />
                        </Col>
                        <Col className="no-margin" lg={4} md={4} sm={4} xs={4}>
                            <img className="img-quienes-somos-s" alt="Quienes somos" src={ImgFoto3} />
                        </Col>
                        <Col className="no-margin" lg={4} md={4} sm={4} xs={4}>
                            <img className="img-quienes-somos-s" alt="Quienes somos" src={ImgFoto4} />
                        </Col>
                    </Row>
                </Col>
              </Row> */}
            </Container>
          </div>
          <Chat clickOut={showItems} chatOpen={chatOpen}/>
          <Footer clickOut={showItems} />
          <VolverInicio Path={"/inicio"} Texto={"volver-inicio.volver"} />
        </div>
    );
}

export default QuienesSomos;
