import "./Notificaciones.css";
import AlertIcon from "./src/alert.svg";
import { useEffect, useState } from "react";

function Notificaciones() {
    const [notificacion, setNotificacion] = useState("");
    const [show, setShow] = useState(false);

    useEffect(() => {
        fetch(`https://notificador.tabsa.cl/api/alerta`, { method: "GET" }).then(response => {
            if (response.ok) {
                response.json().then(data => { 
                    try {

                        document.getElementById("div-notificaciones").style.backgroundColor = data.backgroundColor;
                        document.getElementById("alert-text-notificaciones").style.color = data.color;
                        document.getElementById("alert-close-button").style.color = data.color;
                        document.getElementById("alert-icon-notificaciones").getSVGDocument().getElementById("alert-path").setAttribute("fill", data.color);
                        
                        setNotificacion(data.alerta)
                        setShow(data.activa)
                    }
                    catch (error){
                        console.log(error)
                    }
                })
            }
            else {
                console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`)
            }

        })
    }, [])

    return (
        <div id="div-notificaciones" className={`div-notificaciones ${show ? "show-notf" : "hide-notf"}`}>
            <p id="alert-text-notificaciones" className="alert-text-notificaciones"><object id="alert-icon-notificaciones" className="alert-icon-notificaciones" type="image/svg+xml" data={AlertIcon}></object> {notificacion}</p>
            <span id="alert-close-button" className="alert-close-button" onClick={() => setShow(false)}>X</span>
        </div>
    )
}

export default Notificaciones;