import "./Contactanos.css";
import { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Swal from "sweetalert2";
import { Col, Container, Row, Form, Button } from "react-bootstrap";
import EstadoCruce from '../../components/EstadoCruce/EstadoCruce';
import Navegacion from "../../components/Navegacion/Navegacion";
import VolverInicio from "../../components/VolverInicio/VolverInicio";
import Footer from "../../components/Footer/Footer";
import Chat from "../../components/Chat/Chat";
import ImgBack from "./src/img_back.jpg";
import moment from "moment";
import CloseButtonIcon from "./src/close-circle.svg";
import CelularIcon from "./src/celular.svg";
import Logo from "./src/logo_tabsa.png";
import ImgLoading from "../../components/cargando.webp";

import { emailRegex, telefonoRegex } from "../../regexs/Regex";

import { useTranslation } from "react-i18next";

function Contactanos() {
    const [t, i18n] = useTranslation("global");

    const [cruces, setCruces] = useState([]);
    const [crucesSuspendidos, setCrucesSuspendidos] = useState([]);
    const [showCruce, setShowCruce] = useState(false);
    const [rutas, setRutas] = useState([]);

    const [showModal, setShowModal] = useState(false);

    const [enviado, setEnviado] = useState(false);

    const [nombre, setNombre] = useState("");
    const [apellidos, setApellidos] = useState("");
    const [codigoArea, setCodigoArea] = useState("");
    const [telefono, setTelefono] = useState("");
    const [confirmacionTelefono, setConfirmacionTelefono] = useState("");
    const [correo, setCorreo] = useState("");
    const [confirmarCorreo, setConfirmarCorreo] = useState("");
    const [ubicacion, setUbicacion] = useState("");
    const [tipoMensaje, setTipoMensaje] = useState("");
    const [mensaje, setMensaje] = useState("");

    
    const [nombreValid, setNombreValid] = useState(true);
    const [apellidosValid, setApellidosValid] = useState(true);
    const [codigoAreaValid, setCodigoAreaValid] = useState(true);
    const [telefonoValid, setTelefonoValid] = useState(true);
    const [confirmacionTelefonoValid, setConfirmacionTelefonoValid] = useState(true);
    const [correoValid, setCorreoValid] = useState(true);
    const [confirmarCorreoValid, setConfirmarCorreoValid] = useState(true);
    const [ubicacionValid, setUbicacionValid] = useState(true);
    const [tipoMensajeValid, setTipoMensajeValid] = useState(true);
    const [mensajeValid, setMensajeValid] = useState(true);

  useEffect(() => {
    
    let firstDate = moment(new Date()).format("DD-MM-yyyy");
    let secondDate = moment(new Date().setDate(new Date().getDate() + 1)).format("DD-MM-yyyy");

    fetch(`https://api-cruce.tabsa.cl/api/cruce/20/fecha/${firstDate}/embarcadero/0`, { method: "GET" }).then(firstDateResponse => {
        if (firstDateResponse.ok) {
            firstDateResponse.json().then(firstDateData => { 

                let newCruces = firstDateData.data.map((d) => ({ 
                    estado: d.estado_detalle.toLowerCase(), 
                    origen: d.origen, 
                    destino: d.destino, 
                    hora: d.zarpeOriginal.split(" ")[1].substring(0, 5), 
                    fecha: d.zarpeOriginal.split(" ")[0]
                }))
                setCruces(newCruces);

                fetch(`https://api-cruce.tabsa.cl/api/cruce/20/fecha/${secondDate}/embarcadero/0`, { method: "GET" }).then(secondDateResponse => {
                    if (secondDateResponse.ok) {
                        secondDateResponse.json().then(secondDateData => { 
                            
                            let newSecondCruces = secondDateData.data.map((d) => ({ 
                                estado: d.estado_detalle.toLowerCase(), 
                                origen: d.origen, 
                                destino: d.destino, 
                                hora: d.zarpeOriginal.split(" ")[1].substring(0, 5), 
                                fecha: d.zarpeOriginal.split(" ")[0]
                            }))

                            let concats = newCruces.concat(newSecondCruces);
                            setCruces(concats);

                            let newCrucesSuspendidos = [];
                
                            concats.map((d) => {
                                if (d.estado === "suspendido" || d.estado === "cancelado") {
                                    newCrucesSuspendidos.push(d);
                                }
                            });

                            setCrucesSuspendidos(newCrucesSuspendidos);
                            setShowCruce(newCrucesSuspendidos.length > 0)
                        })
                    }
                    else {
                        console.log(`ERROR ${secondDateResponse.url} status ${secondDateResponse.status} ${secondDateResponse.statusText}`)
                    }

                })
            })
        }
        else {
            console.log(`ERROR ${firstDateResponse.url} status ${firstDateResponse.status} ${firstDateResponse.statusText}`)
        }
    }).catch(error => {
      console.log(JSON.stringify(error));
    });

    fetch(`${process.env.REACT_APP_API_BASEURL}/Rutas/rutas`, { method: "GET" }).then(response => {
        if (response.ok) {
          response.json().then(data => {
            setRutas(data.data);
          })
        }
        else {
          console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
        }
    }).catch(error => {
        console.log(JSON.stringify(error));
    });
  }, [])

  const [chatOpen, setChatOpen] = useState(false);
  const [showSelectLanguage, setShowSelectLanguage] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);

  function showItems(chat, calendarioIda, calendarioVuelta, idiomas, login) {
    setChatOpen(chat);
    setShowSelectLanguage(idiomas);
    setLoginOpen(login);
  }

  function validarCorreo(value){
    setConfirmarCorreo(value);
    if (!emailRegex.test(correo)) {
        setCorreoValid(false);
    }
  }

  function validarTelefono(){
    if (!telefonoRegex.test(telefono) || telefono.trim().length === 0) {
        setTelefonoValid(false);
    }
  }

    return (
        <div className="Contactanos">
          <EstadoCruce clickOut={showItems} cruces={cruces} crucesSuspendidos={crucesSuspendidos} showCruce={showCruce} />
          <div className={`slider-top-shadow${!showCruce ? "-off": ""}`}>
            <Navegacion clickOut={showItems} rutas={rutas} showSelectLanguage={showSelectLanguage} />
          </div>
          <div className="div-contactanos-s" onClick={() => { showItems(false, false, false, false, false) }}>
            <img className="img-contactanos-s" alt={"back image"} src={ImgBack} />
          </div>
          <div className="div-contactanos-body" onClick={() => { showItems(false, false, false, false, false) }}>
            <Container>
              <Row className="justify-content-center">
                <Col lg={8}>
                    <p className="title-contactanos-s">{t("nosotros.contactanos.titulo")}</p>
                </Col>
              </Row>
              <Row className="justify-content-center row-content-ctt-s">
                <Col lg={8}>
                    <Row className="row-content-ctt-s">
                        <Col lg={6} md={6}>
                            <Form.Label className="label-contactanos" htmlFor="txtNombre">{t("nosotros.contactanos.nombre")}</Form.Label>
                            <Form.Control 
                                className={`form-field-contactanos ${nombreValid ? "" : "form-field-contactanos-invalid"}`}  
                                type="text" 
                                id="txtNombre"
                                placeholder={t("nosotros.contactanos.nombre")}
                                value={nombre} 
                                onChange={(evt) => setNombre(evt.target.value)}
                            />
                            {
                                !nombreValid &&
                                <Form.Text id="txtNombreValid" className="form-text-contactanos-invalid">
                                    {t("nosotros.contactanos.nombre-valid")}
                                </Form.Text>
                            }
                        </Col>
                        <Col lg={6} md={6}>
                            <Form.Label className="label-contactanos" htmlFor="txtApellidos">{t("nosotros.contactanos.apellidos")}</Form.Label>
                            <Form.Control 
                                className={`form-field-contactanos ${apellidosValid ? "" : "form-field-contactanos-invalid"}`}
                                type="text" 
                                id="txtApellidos"
                                placeholder={t("nosotros.contactanos.apellidos")}
                                value={apellidos} 
                                onChange={(evt) => setApellidos(evt.target.value)}
                            />
                            {
                                !apellidosValid &&
                                <Form.Text id="txtApellidosValid" className="form-text-contactanos-invalid">
                                    {t("nosotros.contactanos.apellidos-valid")}
                                </Form.Text>
                            }
                        </Col>
                    </Row>
                    <Row className="row-content-ctt-s">
                        <Col lg={4} md={4}>
                            <Form.Label className="label-contactanos" htmlFor="ddlCodigoArea">{t("nosotros.contactanos.codigo-area")}</Form.Label>
                            <Form.Select
                                className={`form-field ${codigoAreaValid ? "" : "form-field-contactanos-invalid"}`} 
                                id="ddlCodigoArea" 
                                aria-label={t("nosotros.contactanos.tipo-mensaje.s0")} 
                                placeholder={t("nosotros.contactanos.tipo-mensaje.s0")}
                                onChange={(evt) => {
                                    setCodigoArea(evt.target.value);
                                }}>
                                <option value={""} className="unselectable">{t("nosotros.contactanos.tipo-mensaje.s0")}</option>
                                <option value="+56"> Chile 	+56 </option>
                                <option value="+93"> Afghanistan 	+93 </option>
                                <option value="+355"> Albania 	+355 </option>
                                <option value="+213"> Algeria 	+213 </option>
                                <option value="+1-684"> American Samoa	+1-684 </option>
                                <option value="+376"> Andorra, Principality of 	+376 </option>
                                <option value="+244"> Angola	+244 </option>
                                <option value="+1-264"> Anguilla 	+1-264 </option>
                                <option value="+672"> Antarctica	+672 </option>
                                <option value="+1-268"> Antigua and Barbuda	+1-268 </option>
                                <option value="+54"> Argentina 	+54 </option>
                                <option value="+374"> Armenia	+374 </option>
                                <option value="+297"> Aruba	+297 </option>
                                <option value="+61"> Australia	+61 </option>
                                <option value="+43"> Austria	+43 </option>
                                <option value="+994"> Azerbaijan or Azerbaidjan	+994 </option>
                                <option value="+1-242"> Bahamas, Commonwealth of The	+1-242 </option>
                                <option value="+973"> Bahrain, Kingdom of	+973 </option>
                                <option value="+880"> Bangladesh	+880 </option>
                                <option value="+1-246"> Barbados 	+1-246 </option>
                                <option value="+375"> Belarus	+375 </option>
                                <option value="+32"> Belgium 	+32 </option>
                                <option value="+501"> Belize	+501 </option>
                                <option value="+229"> Benin	+229 </option>
                                <option value="+1-441"> Bermuda 	+1-441 </option>
                                <option value="+975"> Bhutan, Kingdom of	+975 </option>
                                <option value="+591"> Bolivia 	+591 </option>
                                <option value="+387"> Bosnia and Herzegovina 	+387 </option>
                                <option value="+267"> Botswana	+267 </option>
                                <option value="+55"> Brazil 	+55 </option>
                                <option value="+673"> Brunei 	+673 </option>
                                <option value="+359"> Bulgaria 	+359 </option>
                                <option value="+226"> Burkina Faso	+226 </option>
                                <option value="+257"> Burundi	+257 </option>
                                <option value="+855"> Cambodia, Kingdom of	+855 </option>
                                <option value="+237"> Cameroon	+237 </option>
                                <option value="+1"> Canada 	+1 </option>
                                <option value="+238"> Cape Verde 	+238 </option>
                                <option value="+1-345"> Cayman Islands 	+1-345 </option>
                                <option value="+236"> Central African Republic 	+236 </option>
                                <option value="+235"> Chad 	+235 </option>
                                <option value="+86"> China 	+86 </option>
                                <option value="+53"> Christmas Island 	+53 </option>
                                <option value="+61"> Cocos (Keeling) Islands 	+61 </option>
                                <option value="+57"> Colombia 	+57 </option>
                                <option value="+269"> Comoros, Union of the 	+269 </option>
                                <option value="+243"> Congo, Democratic Republic of the 	+243 </option>
                                <option value="+242"> Congo, Republic of the	+242 </option>
                                <option value="+682"> Cook Islands	+682 </option>
                                <option value="+506"> Costa Rica 	+506 </option>
                                <option value="+225"> Cote D'Ivoire 	+225 </option>
                                <option value="+385"> Croatia 	+385 </option>
                                <option value="+53"> Cuba 	+53 </option>
                                <option value="+357"> Cyprus 	+357 </option>
                                <option value="+420"> Czech Republic	+420 </option>
                                <option value="+45"> Denmark 	+45 </option>
                                <option value="+253"> Djibouti	+253 </option>
                                <option value="+1-767"> Dominica 	+1-767 </option>
                                <option value="+1-809 "> Dominican Republic 	+1-809   </option>
                                <option value="+1-829 "> Dominican Republic 	+1-829   </option>
                                <option value="+670"> East Timor	+670 </option>
                                <option value="+593"> Ecuador 	+593  </option>
                                <option value="+20"> Egypt	+20 </option>
                                <option value="+503"> El Salvador 	+503 </option>
                                <option value="+240"> Equatorial Guinea	+240 </option>
                                <option value="+291"> Eritrea	+291 </option>
                                <option value="+372"> Estonia	+372 </option>
                                <option value="+251"> Ethiopia	+251 </option>
                                <option value="+500"> Falkland Islands (Islas Malvinas) 	+500 </option>
                                <option value="+298"> Faroe Islands 	+298 </option>
                                <option value="+679"> Fiji 	+679 </option>
                                <option value="+358"> Finland 	+358 </option>
                                <option value="+33"> France 	+33 </option>
                                <option value="+594"> French Guiana or French Guyana 	+594 </option>
                                <option value="+689"> French Polynesia	+689 </option>
                                <option value="+241"> Gabon	+241 </option>
                                <option value="+220"> Gambia, The 	+220 </option>
                                <option value="+995"> Georgia	+995 </option>
                                <option value="+49"> Germany 	+49 </option>
                                <option value="+233"> Ghana	+233 </option>
                                <option value="+350"> Gibraltar 	+350 </option>
                                <option value="+30"> Greece 	+30 </option>
                                <option value="+299"> Greenland 	+299 </option>
                                <option value="+1-473"> Grenada 	+1-473 </option>
                                <option value="+590"> Guadeloupe	+590 </option>
                                <option value="+1-671"> Guam	+1-671 </option>
                                <option value="+502"> Guatemala 	+502 </option>
                                <option value="+224"> Guinea	+224 </option>
                                <option value="+245"> Guinea-Bissau	+245 </option>
                                <option value="+592"> Guyana	+592 </option>
                                <option value="+509"> Haiti 	+509 </option>
                                <option value="+504"> Honduras 	+504 </option>
                                <option value="+852"> Hong Kong 	+852 </option>
                                <option value="+36"> Hungary 	+36 </option>
                                <option value="+354"> Iceland 	+354 </option>
                                <option value="+91"> India 	+91 </option>
                                <option value="+62"> Indonesia	+62 </option>
                                <option value="+98"> Iran, Islamic Republic of	+98 </option>
                                <option value="+964"> Iraq 	+964 </option>
                                <option value="+353"> Ireland 	+353 </option>
                                <option value="+972"> Israel 	+972 </option>
                                <option value="+39"> Italy 	+39 </option>
                                <option value="+1-876"> Jamaica 	+1-876 </option>
                                <option value="+81"> Japan 	+81 </option>
                                <option value="+962"> Jordan	+962 </option>
                                <option value="+7"> Kazakstan or Kazakhstan	+7 </option>
                                <option value="+254"> Kenya	+254 </option>
                                <option value="+686"> Kiribati	+686 </option>
                                <option value="+850"> North Korea	+850 </option>
                                <option value="+82"> South Korea 	+82 </option>
                                <option value="+965"> Kuwait 	+965 </option>
                                <option value="+996"> Kyrgyzstan	+996 </option>
                                <option value="+856"> Lao People's Democratic Republic	+856 </option>
                                <option value="+371"> Latvia 	+371 </option>
                                <option value="+961"> Lebanon 	+961 </option>
                                <option value="+266"> Lesotho	+266 </option>
                                <option value="+231"> Liberia 	+231 </option>
                                <option value="+218"> Libya	+218 </option>
                                <option value="+423"> Liechtenstein 	+423 </option>
                                <option value="+370"> Lithuania	+370 </option>
                                <option value="+352"> Luxembourg 	+352 </option>
                                <option value="+853"> Macau 	+853 </option>
                                <option value="+389"> North Macedonia	+389 </option>
                                <option value="+261"> Madagascar	+261 </option>
                                <option value="+265"> Malawi	+265 </option>
                                <option value="+60"> Malaysia 	+60 </option>
                                <option value="+960"> Maldives 	+960 </option>
                                <option value="+223"> Mali 	+223 </option>
                                <option value="+356"> Malta 	+356 </option>
                                <option value="+692"> Marshall Islands	+692 </option>
                                <option value="+596"> Martinique 	+596 </option>
                                <option value="+222"> Mauritania 	+222 </option>
                                <option value="+230"> Mauritius 	+230 </option>
                                <option value="+269"> Mayotte	+269 </option>
                                <option value="+52"> Mexico 	+52 </option>
                                <option value="+691"> Micronesia, Federated States of	+691 </option>
                                <option value="+373"> Moldova, Republic of	+373 </option>
                                <option value="+377"> Monaco, Principality of	+377 </option>
                                <option value="+976"> Mongolia	+976 </option>
                                <option value="+1-664"> Montserrat 	+1-664 </option>
                                <option value="+212"> Morocco 	+212 </option>
                                <option value="+258"> Mozambique	+258 </option>
                                <option value="+95"> Myanmar, Union of	+95 </option>
                                <option value="+264"> Namibia	+264 </option>
                                <option value="+674"> Nauru	+674 </option>
                                <option value="+977"> Nepal 	+977 </option>
                                <option value="+31"> Netherlands 	+31 </option>
                                <option value="+599"> Netherlands Antilles	+599 </option>
                                <option value="+687"> New Caledonia 	+687 </option>
                                <option value="+64"> New Zealand 	+64 </option>
                                <option value="+505"> Nicaragua 	+505 </option>
                                <option value="+227"> Niger 	+227 </option>
                                <option value="+234"> Nigeria 	+234 </option>
                                <option value="+683"> Niue	+683 </option>
                                <option value="+672"> Norfolk Island 	+672 </option>
                                <option value="+1-670"> Northern Mariana Islands	+1-670 </option>
                                <option value="+47"> Norway 	+47 </option>
                                <option value="+968"> Oman, Sultanate of	+968 </option>
                                <option value="+92"> Pakistan	+92 </option>
                                <option value="+680"> Palau	+680 </option>
                                <option value="+970"> Palestinian State	+970 </option>
                                <option value="+507"> Panama 	+507 </option>
                                <option value="+675"> Papua New Guinea	+675 </option>
                                <option value="+595"> Paraguay 	+595 </option>
                                <option value="+51"> Peru 	+51 </option>
                                <option value="+63"> Philippines 	+63 </option>
                                <option value="+48"> Poland 	+48 </option>
                                <option value="+351"> Portugal 	+351 </option>
                                <option value="+1-787"> Puerto Rico 	+1-787 </option>
                                <option value="+1-939"> Puerto Rico 	+1-939 </option>
                                <option value="+974"> Qatar, State of 	+974  </option>
                                <option value="+262"> Reunion	+262 </option>
                                <option value="+40"> Romania 	+40 </option>
                                <option value="+7"> Russian Federation 	+7 </option>
                                <option value="+250"> Rwanda	+250 </option>
                                <option value="+290"> Saint Helena 	+290 </option>
                                <option value="+1-869"> Saint Kitts and Nevis	+1-869 </option>
                                <option value="+1-758"> Saint Lucia 	+1-758 </option>
                                <option value="+508"> Saint Pierre and Miquelon 	+508 </option>
                                <option value="+1-784"> Saint Vincent and the Grenadines 	+1-784 </option>
                                <option value="+685"> Samoa	+685 </option>
                                <option value="+378"> San Marino 	+378 </option>
                                <option value="+239"> Sao Tome and Principe 	+239 </option>
                                <option value="+966"> Saudi Arabia 	+966 </option>
                                <option value="+221"> Senegal 	+221 </option>
                                <option value="+248"> Seychelles 	+248 </option>
                                <option value="+232"> Sierra Leone 	+232 </option>
                                <option value="+65"> Singapore 	+65 </option>
                                <option value="+421"> Slovakia	+421 </option>
                                <option value="+386"> Slovenia 	+386 </option>
                                <option value="+677"> Solomon Islands	+677 </option>
                                <option value="+252"> Somalia 	+252 </option>
                                <option value="+27"> South Africa	+27 </option>
                                <option value="+34"> Spain 	+34 </option>
                                <option value="+94"> Sri Lanka 	+94 </option>
                                <option value="+249"> Sudan 	+249 </option>
                                <option value="+597"> Suriname	+597 </option>
                                <option value="+268"> Swaziland, Kingdom of 	+268 </option>
                                <option value="+46"> Sweden 	+46 </option>
                                <option value="+41"> Switzerland 	+41 </option>
                                <option value="+963"> Syria	+963 </option>
                                <option value="+886"> Taiwan	+886 </option>
                                <option value="+992"> Tajikistan	+992 </option>
                                <option value="+255"> Tanzania, United Republic of	+255 </option>
                                <option value="+66"> Thailand	+66 </option>
                                <option value="+690"> Tokelau 	+690 </option>
                                <option value="+676"> Tonga, Kingdom of	+676 </option>
                                <option value="+1-868"> Trinidad and Tobago 	+1-868 </option>
                                <option value="+216"> Tunisia 	+216 </option>
                                <option value="+90"> Turkey 	+90 </option>
                                <option value="+993"> Turkmenistan	+993 </option>
                                <option value="+1-649"> Turks and Caicos Islands 	+1-649 </option>
                                <option value="+688"> Tuvalu 	+688 </option>
                                <option value="+256"> Uganda, Republic of	+256 </option>
                                <option value="+380"> Ukraine	+380 </option>
                                <option value="+971"> United Arab Emirates	+971 </option>
                                <option value="+44"> United Kingdom 	+44 </option>
                                <option value="+1"> United States 	+1 </option>
                                <option value="+598"> Uruguay, Oriental Republic of	+598 </option>
                                <option value="+998"> Uzbekistan	+998 </option>
                                <option value="+678"> Vanuatu	+678 </option>
                                <option value="+418"> Vatican City State	+418 </option>
                                <option value="+58"> Venezuela 	+58 </option>
                                <option value="+84"> Vietnam 	+84 </option>
                                <option value="+1-284"> Virgin Islands, British 	+1-284 </option>
                                <option value="+1-340"> Virgin Islands, United States 	+1-340 </option>
                                <option value="+681"> Wallis and Futuna Islands 	+681 </option>
                                <option value="+967"> Yemen 	+967 </option>
                                <option value="+260"> Zambia, Republic of 	+260 </option>
                                <option value="+263"> Zimbabwe, Republic of 	+263 </option>
                            </Form.Select>
                            {
                                !codigoAreaValid &&
                                <Form.Text id="txtCodigoAreaValid" className="form-text-contactanos-invalid">
                                    {t("nosotros.contactanos.codigo-area-valid")}
                                </Form.Text>
                            }
                        </Col>
                        <Col lg={4} md={4}>
                            <Form.Label className="label-contactanos" htmlFor="txtTelefono">{t("nosotros.contactanos.telefono")}</Form.Label>
                            <Form.Control 
                                className={`form-field-contactanos ${telefonoValid ? "" : "form-field-contactanos-invalid"}`} 
                                type="number" 
                                id="txtTelefono"
                                placeholder={t("nosotros.contactanos.telefono")}
                                value={telefono} 
                                onChange={(evt) => setTelefono(evt.target.value)}
                            />
                            {
                                !telefonoValid &&
                                <Form.Text id="txtTelefonoValid" className="form-text-contactanos-invalid">
                                    {t("nosotros.contactanos.telefono-valid")}
                                </Form.Text>
                            }
                        </Col>
                        <Col lg={4} md={4}>
                            <Form.Label className="label-contactanos" htmlFor="txtConfirmarTelefono">{t("nosotros.contactanos.confirmar-telefono")}</Form.Label>
                            <Form.Control 
                                className={`form-field-contactanos ${confirmacionTelefonoValid ? "" : "form-field-contactanos-invalid"}`} 
                                type="number" 
                                id="txtConfirmarTelefono"
                                placeholder={t("nosotros.contactanos.confirmar-telefono")}
                                value={confirmacionTelefono} 
                                onChange={(evt) => setConfirmacionTelefono(evt.target.value)}
                            />
                            {
                                !confirmacionTelefonoValid &&
                                <Form.Text id="txtConfirmacionTelefonoValid" className="form-text-contactanos-invalid">
                                    {t("nosotros.contactanos.confirmar-telefono-valid")}
                                </Form.Text>
                            }
                        </Col>
                    </Row>
                    <Row className="row-content-ctt-s">
                        <Col lg={6} md={6}>
                            <Form.Label className="label-contactanos" htmlFor="txtCorreo">{t("nosotros.contactanos.correo")}</Form.Label>
                            <Form.Control 
                                className={`form-field-contactanos ${correoValid ? "" : "form-field-contactanos-invalid"}`} 
                                type="text" 
                                id="txtCorreo"
                                placeholder={t("nosotros.contactanos.correo")}
                                value={correo} 
                                onChange={(evt) => setCorreo(evt.target.value)}

                            />
                            {
                                !correoValid &&
                                <Form.Text id="txtCorreoValid" className="form-text-contactanos-invalid" onChange={(evt) => setCorreo(evt.target.value)}>
                                    {t("nosotros.contactanos.correo-valid")}
                                </Form.Text>
                            }
                        </Col>
                        <Col lg={6} md={6}>
                            <Form.Label className="label-contactanos" htmlFor="txtConfirmarCorreo">{t("nosotros.contactanos.confirmar-correo")}</Form.Label>
                            <Form.Control 
                                className={`form-field-contactanos ${confirmarCorreoValid ? "" : "form-field-contactanos-invalid"}`}  
                                type="text" 
                                id="txtConfirmarCorreo"
                                placeholder={t("nosotros.contactanos.confirmar-correo")}
                                value={confirmarCorreo} 
                                onChange={(evt) => setConfirmarCorreo(evt.target.value)}
                            />
                            {
                                !confirmarCorreoValid &&
                                <Form.Text id="txtConfirmarCorreoValid" className="form-text-contactanos-invalid">
                                    {t("nosotros.contactanos.confirmar-correo-valid")}
                                </Form.Text>
                            }
                        </Col>
                    </Row>
                    <Row className="row-content-ctt-s">
                        <Col lg={6} md={6}>
                            <Form.Label className="label-contactanos" htmlFor="txtUbicacion">{t("nosotros.contactanos.ubicacion")}</Form.Label>
                            <Form.Select 
                                className={`form-field-contactanos ${ubicacionValid ? "" : "form-field-contactanos-invalid"}`} 
                                id="txtUbicacion" 
                                aria-label={t("nosotros.contactanos.tipo-mensaje.s0")} 
                                placeholder={t("nosotros.contactanos.tipo-mensaje.s0")}
                                onChange={(evt) => {
                                    setUbicacion(evt.target.value);
                                }}
                            >
                                <option value={""} className="unselectable">{t("nosotros.contactanos.tipo-mensaje.s0")}</option>
                                <option value={"Punta Arenas"}>Punta Arenas</option>
                                <option value={"Puerto Natales"}>Puerto Natales</option>
                                <option value={"Puerto Williams"}>Puerto Williams</option>
                                <option value={"Porvenir"}>Porvenir</option>
                                <option value={"Primera Angostura"}>Primera Angostura</option>

                            </Form.Select>
                            {
                                !ubicacionValid &&
                                <Form.Text id="txtUbicacionValid" className="form-text-contactanos-invalid">
                                    {t("nosotros.contactanos.ubicacion-valid")}
                                </Form.Text>
                            }
                        </Col>
                        <Col lg={6} md={6}>
                            <Form.Label className="label-contactanos" htmlFor="ddlTipoMensaje">{t("nosotros.contactanos.tipo-mensaje.titulo")}</Form.Label>
                            <Form.Select 
                                className={`form-field-contactanos ${tipoMensajeValid ? "" : "form-field-contactanos-invalid"}`} 
                                id="ddlTipoMensaje" 
                                aria-label={t("nosotros.contactanos.tipo-mensaje.s0")} 
                                placeholder={t("nosotros.contactanos.tipo-mensaje.s0")}
                                onChange={(evt) => {
                                    setTipoMensaje(evt.target.value);
                                }}
                            >
                                <option value={""} className="unselectable">{t("nosotros.contactanos.tipo-mensaje.s0")}</option>
                                <option value={"sugerencia"}>{t("nosotros.contactanos.tipo-mensaje.s1")}</option>
                                <option value={"reclamo"}>{t("nosotros.contactanos.tipo-mensaje.s2")}</option>
                                <option value={"consulta"}>{t("nosotros.contactanos.tipo-mensaje.s3")}</option>
                                <option value={"felicitacion"}>{t("nosotros.contactanos.tipo-mensaje.s4")}</option>
                            </Form.Select>
                            {
                                !tipoMensajeValid &&
                                <Form.Text id="txtConfirmarCorreoValid" className="form-text-contactanos-invalid">
                                    {t("nosotros.contactanos.tipo-mensaje-valid")}
                                </Form.Text>
                            }
                        </Col>
                    </Row>
                    <Row className="row-content-ctt-s">
                        <Col lg={12} md={12}>
                            <Form.Label className="label-contactanos" htmlFor="txtMensaje">{t("nosotros.contactanos.mensaje")}</Form.Label>
                            <Form.Control 
                                className={`form-field ${mensajeValid ? "" : "form-field-contactanos-invalid"}`}
                                as="textarea" 
                                id="txtMensaje"
                                placeholder={t("nosotros.contactanos.mensaje")}
                                value={mensaje} 
                                onChange={(evt) => {
                                    if (evt.target.value.length <= 500) {
                                        setMensaje(evt.target.value)
                                    }
                                }}
                            />
                            {
                                !mensajeValid &&
                                <>
                                    <Form.Text id="txtMensajeValid" className="form-text-contactanos-invalid">
                                        {t("nosotros.contactanos.mensaje-valid")}
                                    </Form.Text>
                                    <br />
                                </>
                            }
                            <Form.Text id="txtMensajeLength" className="form-caracteres-contactanos">
                                { 500 - mensaje.length } {t("nosotros.contactanos.caracteres")}
                            </Form.Text>
                            <br />
                            <br />
                            <br />
                            <Form.Text id="txtDetalle" className="form-requeridos-contactanos">
                                {t("nosotros.contactanos.detalle")}
                            </Form.Text>
                        </Col>
                    </Row>
                    <Row className="justify-content-center row-content-ctt-s">
                        <Col lg={4}>
                            <Button className="btn-enviar-contactanos" disabled={enviado} onClick={() => {
                                setEnviado(true);

                                setNombreValid(true);
                                setApellidosValid(true);
                                setCodigoAreaValid(true);
                                setTelefonoValid(true);
                                setConfirmacionTelefonoValid(true);
                                setCorreoValid(true);
                                setConfirmarCorreoValid(true);
                                setUbicacionValid(true);
                                setTipoMensajeValid(true);
                                setMensajeValid(true);

                                
                                if (nombre.trim().length === 0) {
                                    setNombreValid(false);
                                }
                                if (apellidos.trim().length === 0) {
                                    setApellidosValid(false);
                                }
                                if (codigoArea.trim().length === 0) {
                                    setCodigoAreaValid(false);
                                }
                                if (!telefonoRegex.test(telefono) || telefono.trim().length === 0) {
                                    setTelefonoValid(false);
                                }
                                if (telefono !== confirmacionTelefono) {
                                    setConfirmacionTelefonoValid(false);
                                }
                                if (!emailRegex.test(correo)) {
                                    setCorreoValid(false);
                                }
                                if (correo !== confirmarCorreo) {
                                    setConfirmarCorreoValid(false);
                                }
                                if (ubicacion.trim().length === 0) {
                                    setUbicacionValid(false);
                                }
                                if (tipoMensaje === "") {
                                    setTipoMensajeValid(false);
                                }
                                if (mensaje.trim().length === 0) {
                                    setMensajeValid(false);
                                }

                                if (nombreValid && apellidosValid && codigoAreaValid && telefonoValid && 
                                    confirmacionTelefonoValid && correoValid && confirmarCorreoValid && 
                                    ubicacionValid && tipoMensajeValid && mensajeValid) {
                                    
                                    fetch(`https://api-correo.tabsa.cl/api/correo/reclamo-sugerencia`, { 
                                        method: "POST",
                                        headers: {'Content-Type':'application/json'},
                                        body: JSON.stringify({
                                            "nombre": nombre.trim(),
                                            "apellido": apellidos.trim(),
                                            "correo": correo.trim(),
                                            "telefono": `${codigoArea} ${telefono}`,
                                            "mensaje": mensaje,
                                            "ubicacion": ubicacion,
                                            "tipo": tipoMensaje
                                        })
                                    }).then((response) => {
                                        if (response.ok) {
                                            Swal.fire({
                                                title: t("nosotros.contactanos.swal.titulo"),
                                                text: t("nosotros.contactanos.swal.descripcion"),
                                                icon: "success",
                                                confirmButtonText: t("nosotros.contactanos.swal.boton")
                                            })
                                            .then((result) => {
                                                setEnviado(false);
                                                window.location.href = "https://www.tabsa.cl"
                                                //setShowModal(true);
                                            })
                                        }
                                        else {
                                            console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
                                        }
                                    }).catch(error => {
                                        console.log(JSON.stringify(error));
                                    });
                                    
                                }
                                else {
                                    setEnviado(false);
                                }

                            }}>{t("nosotros.contactanos.enviar")}
                            </Button>
                        </Col>
                    </Row>
                    {
                        enviado &&
                        <Row className="justify-content-center">
                            <Col lg={1}>
                                <img src={ImgLoading} className="img-loading" alt="loading" />
                            </Col>
                        </Row>
                    }
                </Col>
              </Row>
            </Container>
          </div>
          <Chat clickOut={showItems} chatOpen={chatOpen}/>
          <Footer clickOut={showItems} />
          {/*<VolverInicio Path={"/inicio"} Texto={"volver-inicio.volver"} /> */}
          <Modal className="modal-contactanos" size="lg" show={showModal}>
            <Modal.Body>
              <img className="img-modal-close-pf" src={CloseButtonIcon} alt="close button" onClick={() => setShowModal(false)} />
              <p className="subp-contactanos-s">{t("nosotros.contactanos.modal.titulo")}</p>
              <p className="modalp-contactanos-s">{t("nosotros.contactanos.modal.p1")}</p>
              
              <Row className="justify-content-center">
                <Col lg={12}>
                    <table className="table-modal-contactanos">
                        <tr>
                            <td className="modal-separador">
                                <p className="p-modal-contactanos">MESA CENTRAL</p>
                                <p className="pdetalle-modal-contactanos"><img src={CelularIcon} alt="" className="img-modal-contactanos" /> +56 61 272 8100</p>
                            </td>
                            <td>
                                <p className="p-modal-contactanos">ANEXOS VENTAS Y RESERVAS</p>
                                <p className="pdetalle-modal-contactanos">#251 | #252 | #253 | #271 | #272 | #275 | #280</p>
                            </td>
                        </tr>
                    </table>
                </Col>
              </Row>
              <Row className="justify-content-center row-content-ctt-s">
                <Col lg={4}>
                    <img className="img-contactanos-logo" src={Logo} alt="tabsa" />
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        </div>
    );
}

export default Contactanos;
